module.exports = {
  comm: {
    home: 'Início',
    pricing: 'Preços',
    blog: 'Clonagem de Voz e API',
    language: 'Idioma:',
    select_language: 'selecionar idioma',
    txt2voice: 'Texto para voz',
    voice2txt: 'Voz para texto',
    voiceclone: 'Clonagem de voz',
    video2txt: 'Vídeo para texto',
    footer_help: 'Precisa de ajuda? Envie-nos um e-mail:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: 'Entrar',
    login_desc: 'Faça login para acessar sua conta do Gerador de Voz do TikTok',
    logout: 'Sair',
  },
  txt2voice: {
    title: 'Gerador de Voz TikTok: Gerar vozes AI TikTok engraçadas',
    description: 'Gere e baixe gratuitamente vozes AI TikTok engraçadas, como a voz de Jessie, a voz de C3PO, a voz de Ghostface...',
    keywords: 'Gerador de voz TikTok, Voz AI TikTok, Gerador de voz Tik Tok',
    main_title_p01: 'Gerador de Voz TikTok',
    main_title_p02: 'Gerar vozes TikTok engraçadas: voz de Jessie, voz de C3PO, voz de Ghostface',
    main_textarea_holder: 'Digite ou cole o texto aqui',
    main_genvoice: 'gerar',
    main_generating: 'gerando',
    main_input_empty: 'por favor, digite ou cole o texto',
    daily_usage_limit_msg: '1) O limite máximo diário de utilizações foi atingido. Por favor, volte amanhã. 2) Se precisar urgentemente de mais utilizações, envie-nos um e-mail.',
    text_max_prompt1: 'Por favor, limite o número de palavras a',
    text_max_prompt2: 'palavras ou menos!',
    popup_nologin_title: 'Atualize seu plano de assinatura',
    popup_nologin_desc: 'Desbloqueie mais tempo de geração de voz atualizando para uma assinatura premium.',
    popup_nologin_btntext: 'Explorar planos de assinatura',
    popup_nosub_title: 'Atualize seu plano de assinatura',
    popup_nosub_desc: 'Desbloqueie mais tempo de geração de voz atualizando para uma assinatura premium.',
    popup_nosub_btntext: 'Explorar planos de assinatura',
    popup_sublimit_title: 'Assinatura esgotada, por favor atualize',
    popup_sublimit_desc: 'Melhore sua experiência com nossos recursos premium e acesso ilimitado.',
    popup_sublimit_btntext: 'Ir para comprar mais',
  },
  pricing: {
    new_features_alert: "📣 Lançamos serviços de 🎙️ Clonagem de Voz e 🤖 API para membros pagantes! (Após o pagamento, envie um e-mail para tiktokaivoicetool@gmail.com para obter ajuda com esses recursos). Além disso, sinta-se à vontade para nos enviar um e-mail para quaisquer solicitações de personalização😃",
    pricing_title: 'De criadores individuais às maiores empresas, temos um plano para você.',
    pricing_desc: 'Mais de 100 vozes naturais e humanas. Mais de 15 idiomas. e quase os preços mais baixos para todos',
    sub_free_name: 'Grátis',
    sub_free_desc: 'Para indivíduos que querem experimentar o áudio AI de ponta',
    sub_free_content: [
      'Limite de 1000 caracteres por mês',
      '5 gerações por dia',
    ],
    sub_starter_name: 'Iniciante',
    sub_starter_desc: 'Para criadores que produzem conteúdo premium para uma audiência global',
    sub_starter_content: [
      '1 clone de voz',
      'Limite de 300.000 caracteres por mês (aproximadamente 7 horas de áudio)',
      'Downloads ilimitados',
      'Suporte por e-mail em 72 horas',
      'Acesso prioritário a novas vozes e recursos',
    ],
    sub_pro_name: 'Pro',
    sub_pro_desc: 'Para criadores que estão expandindo sua produção de conteúdo',
    sub_pro_content: [
      'Acesso à API',
      '3 clones de voz',
      'Limite de 1.000.000 de caracteres por mês (aproximadamente 24 horas de áudio)',
      'Downloads ilimitados',
      'Suporte por e-mail em 48 horas',
      'Acesso prioritário a novas vozes e recursos',
    ],
    period_year: 'Anual',
    period_month: 'Mensal',
    period_month_short: 'mês',
    billed_year: 'Cobrado anualmente',
    most_popular: 'Mais popular',
    discount_quantity: 'Economize $48',
    buy_btntext: 'Começar',
    pay_succ: 'Pagamento bem-sucedido!',
    pay_succ_desc: 'Obrigado pela sua compra. Sua transação foi processada com sucesso.',
    pay_succ_btntext: 'Comece a usar o TikTok Voice',
    pay_fail: 'Pagamento falhou!',
    pay_fail_desc: "Desculpe, não conseguimos processar sua transação. Por favor, tente novamente ou entre em contato com nossa equipe de suporte (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: 'Voltar para a página inicial',
  },
  setting: {
    setting: 'Configurações',
    setting_title: 'Gerencie sua conta, uso e assinatura aqui.',
    basic_info: 'Informações básicas',
    user_name: 'Nome',
    user_email: 'E-mail',
    user_account: 'Conta',
    user_subscript: 'Plano atual',
    user_usage: 'Uso',
    manage_subscript: 'Gerenciar assinatura',
    use_limit_promote: "Você usou 5000 de seu limite mensal de 10000 caracteres.",
    not_logged_in: 'Você não está logado',
    go_to_login: 'Ir para o login >>',
  },
  login: {
    title: "Gerador de Voz do TikTok",
    subtitle: "Faça login para acessar sua conta do Gerador de Voz do TikTok",
    alreadyLoggedIn: "Você já está logado",
    goToHome: "Ir para a página inicial"
  },
  faq: {
    quest1: 'Gerador de Voz TikTok - Melhor ferramenta de voz AI TikTok',
    answer1_1: 'É uma ferramenta de gerador de voz AI TikTok desenvolvida pela mais recente tecnologia tts TikTok, pode gerar vozes TikTok como a voz de Jessie (voz feminina), a voz de Siri, a voz de Ghostface, a voz de C3PO (voz de robô), a voz profunda (narrador), a voz de Skye, a voz calorosa, a voz de Bestie, a voz de Herói (voz de Chiris), a voz empática, a voz séria, a voz de Joey, a voz de Stitch, a voz de Stormtrooper (Star Wars), a voz de Rocket (Guardians of the Galaxy).',
    answer1_2: 'As vozes AI TikTok que serão suportadas em breve incluem: a voz de Derek, a voz de Trickster, a voz de Austin Butler, a voz de repórter de notícias, a voz de Adam, a voz de cachorro, a voz de Miley Cyrus, a voz de Alexa, a voz de alienígena, a voz de animal, a voz de bebê, a voz de computador, a voz de esquilo, a voz de eco, a voz de NPR.',
    answer1_3: 'Além disso, a ferramenta de gerador de voz TikTok suporta vários outros idiomas, incluindo chinês, japonês, coreano, vietnamita, tailandês, hindi, persa, russo, alemão, francês, romeno, tcheco, espanhol, português, bengali, italiano, árabe, urdu, chinês tradicional e malaio.',
    answer1_4: 'Se precisar urgentemente de uma voz espec��fica, envie-me um e-mail.',

quest2: 'Quais são as vantagens do Gerador de Voz TikTok?',
    answer2_1: '- A ferramenta de Gerador de Voz TikTok pode gerar vários tipos de vozes, que são frequentemente usadas nos vídeos TikTok.',
    answer2_2: '- É o mais recente gerador de voz AI que pode gerar vozes tts TikTok semelhantes às humanas.',
    answer2_3: '- É mais conveniente para edição de vídeos em um PC.',
    answer2_4: '- Você pode usar algumas vozes que atualmente não podem ser encontradas no aplicativo tts TikTok.',

quest3: 'Como usar o Gerador de Voz TikTok?',
    answer3_1: 'O Gerador de Voz TikTok é muito fácil de usar:',
    answer3_2: '- Selecione o idioma e o sotaque da voz.',
    answer3_3: '- Digite o texto a ser convertido em voz na caixa de entrada.',
    answer3_4: '- Pressione o botão de gerar e espere alguns segundos.',
    answer3_5: '- Reproduza ou baixe a voz AI TikTok.',

quest4: 'Quais são as vozes TikTok mais famosas e engraçadas?',
    answer4_1: 'A voz masculina mais famosa do TikTok é a voz profunda, oficialmente chamada de narrador.',
    answer4_2: 'Para a voz TikTok mais engraçada, recomendo pessoalmente a voz de Ghostface e a voz de C3PO. Estas são frequentemente usadas para dublar vídeos engraçados do TikTok, pois são muito reconhecíveis e podem atrair rapidamente os espectadores.',

quest5: 'Quais são as vozes TikTok mais irritantes?',
    answer5_1: 'Acredito que realmente não há uma voz TikTok "irritante".',
    answer5_2: 'Depende mais do contexto e do conteúdo em que a voz é usada, o que pode incomodar algumas pessoas. Por exemplo, algumas pessoas podem achar a voz de Jessie irritante porque apareceu com muita frequência no TikTok durante um certo período. No entanto, devido ao seu tom e qualidade, é realmente um efeito de voz TikTok fantástico. Além disso, a dubladora do TikTok por trás dela é muito profissional e também publica seus próprios vídeos no TikTok.',
    answer5_3: 'Portanto, sugiro que todos deixem de lado as preocupações e preconceitos, ouçam várias vezes as vozes desta ferramenta e pacientemente encontrem o efeito de voz TikTok que melhor lhes convier.',

quest6: 'Como usar o Gerador de Voz TikTok para gerar a voz de Jessie?',
    answer6_1: '- No primeiro menu suspenso da ferramenta Gerador de Voz TikTok, selecione English (US), depois no outro menu suspenso, selecione a voz de Jessie (voz feminina).',
    answer6_2: '- Digite o texto na caixa de entrada e clique no botão de gerar.',
    answer6_3: '- Espere alguns segundos ou até dez segundos, você ouvirá a voz AI. Na barra de ferramentas, encontre o botão com uma seta para baixo e clique nele para baixar.',

quest7: 'Como usar o Gerador de Voz TikTok para gerar a voz de C3PO?',
    answer7_1: '- No primeiro menu suspenso da ferramenta Gerador de Voz TikTok, selecione English (US), depois no outro menu suspenso, selecione a voz de C3PO (voz de robô).',
    answer7_2: '- Digite o texto na caixa de entrada e clique no botão de gerar.',
    answer7_3: '- Espere alguns segundos ou até dez segundos, você ouvirá a voz AI. Na barra de ferramentas, encontre o botão com uma seta para baixo e clique nele para baixar.',

quest8: 'Como usar o Gerador de Voz TikTok para gerar a voz de Ghostface?',
    answer8_1: '- No primeiro menu suspenso da ferramenta Gerador de Voz TikTok, selecione English (US), depois no outro menu suspenso, selecione a voz de Ghostface.',
    answer8_2: '- Digite o texto na caixa de entrada e clique no botão de gerar.',
    answer8_3: '- Espere alguns segundos ou até dez segundos, você ouvirá a voz AI. Na barra de ferramentas, encontre o botão com uma seta para baixo e clique nele para baixar.',

quest9: 'Como usar o Gerador de Voz TikTok para gerar a voz de Siri?',
    answer9_1: '- No primeiro menu suspenso da ferramenta Gerador de Voz TikTok, selecione English (US), depois no outro menu suspenso, selecione a voz de Siri.',
    answer9_2: '- Digite o texto na caixa de entrada e clique no botão de gerar.',
    answer9_3: '- Espere alguns segundos ou até dez segundos, você ouvirá a voz AI. Na barra de ferramentas, encontre o botão com uma seta para baixo e clique nele para baixar.',

quest10: 'Como adicionar o efeito de voz TikTok a um vídeo TikTok?',
    answer10_1: 'Se você quiser saber como usar as vozes oficiais do TikTok no TikTok, em breve escreverei uma postagem de blog dedicada a este tópico.',
    answer10_2: 'Aqui, explicarei como publicar sua voz no TikTok após gerá-la e baixá-la do Gerador de Voz TikTok.',
    answer10_3: '1. Se você gerou a voz TikTok no seu PC e deseja carregá-la no TikTok ou em qualquer outro aplicativo de edição de vídeo no seu telefone, você precisará transferir o arquivo de voz para o seu telefone. Para iPhones, você pode usar o airDrop para transferir. Para telefones Android, não estou muito familiarizado com este processo, mas você certamente encontrará métodos e ferramentas para fazê-lo.',
    answer10_4: '2. Uma vez que o arquivo de voz seja transferido para o seu telefone, você pode abrir o TikTok:',
    answer10_5: '- Toque no botão "+" na parte inferior da interface, selecione o vídeo no seu telefone.',
    answer10_6: '- Após carregar o vídeo, encontre um ícone quadrado no lado direito da tela para entrar na página de edição.',
    answer10_7: '- Na parte inferior da página, encontre o botão "adicionar voz" e toque para selecionar o arquivo de voz recém-transferido.',
    answer10_8: '- Nesta interface, você pode fazer alguns ajustes no vídeo, e depois toque no botão no canto superior direito para publicá-lo.',

quest11: 'O TikTok Voice é gratuito?',
    answer11: 'Sim, é uma ferramenta gratuita de geração de voz AI TikTok de texto para fala.',

quest12: 'Onde posso gerar uma voz TikTok popular no meu vídeo TikTok?',
    answer12: 'Você pode visitar https://tiktokvoice.net/ para adicionar vozes AI TikTok ao seu vídeo.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}